import { fuUserPost,fuPost } from "./base";


export const statusText=(statusCode)=>{
    switch(statusCode){
        case '0':return '离线';
        case '1':return '在线';
        case '2':return '在聊';
        case '3':return '活跃';
        default:return '勿扰'
    }
}

export const personHead = (item)=>{

    return (item.vedioHeadImgUrl&&item.vedioHeadImgUrl.length&&item.vedioHeadImgUrl[0])||item.headUrl;
    
}


export const getCheckCode = (area,mobileNumber)=>{
    const url = 'start/get_validate_code.json';
    const requestParams = {
        "mobile" : mobileNumber,
        "code" : "86",
    }
    return fuPost(url,requestParams).then(data=>{
        console.log(data);
        return data;
    }).catch(err=>{
        console.error(err);
        throw new Error();
    });
}

export const login = (mobileNumber,verifyCode) =>{
    const url = 'start/login.json';
    const requestParams = {
        "mobile" : mobileNumber,
        "code" : "86",
        "mobileChkCode" : verifyCode,
    }
    return fuPost(url,requestParams);
}
// 请求模特列表
export const OneOnOneList = (requestParams)=>{
    const tuijianUrl = 'user/model/flowlist_with_banner.json'
    // const requestParams = {

    //     type:'1',
    //     maxTime:''+(new Date().valueOf()*2),
    //     count:'10'
    // }
    return fuUserPost(tuijianUrl,requestParams).then(data=>{
        return data;
    })
}
export const loadOneononeMore = (maxTime)=>{
    const tuijianUrl = 'app/onlines.json';
    console.log('/=/JOSN出错定位3，:',localStorage.getItem('profile'));
    const profile = JSON.parse(localStorage.getItem('profile'));
    const requestParams = {
        type:'1',
        maxTime:''+maxTime,
        count:'10'
    }
    return fuUserPost(tuijianUrl,requestParams).then(data=>{
        return data;
    })
}

export const OneOnOneNewList = ()=>{
    const url = 'user/model/newest.json';
    /*
    params[@"tagId"] = @(self.tagId);
        params[@"start"] = @(max);
        params[@"pageSize"] = @(10);
    */
    const requestParams = {

        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        //tagId:,
        start:'0',
        pageSize:'10'

    }
    return fuUserPost(url,requestParams).then(data=>{
        console.log('\n\n\n');
        console.log(data);
        return data;
    })
}

export const loadOneononeNewMore = (max)=>{
    const url = 'user/model/newest.json';
    /*
    params[@"tagId"] = @(self.tagId);
        params[@"start"] = @(max);
        params[@"pageSize"] = @(10);
    */
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        //tagId:,
        start:max+'',
        pageSize:'10'

    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}


export const OneOnOneOtherList = (tag)=>{
    const url = 'user/model/tagList.json';
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        tagId:tag+'',
        start:'0',
        pageSize:'10'
    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}

export const loadOneononeOtherMore = (max,tag)=>{
    const url = 'user/model/tagList.json';
    const requestParams = {
        type:'1',
        maxTime:''+(new Date().valueOf()*2),
        count:'10',
        tagId:tag+'',
        start:max+'',
        pageSize:'10'
    }
    return fuUserPost(url,requestParams).then(data=>{
        return data;
    })
}
window.futrue=window.futrue||{};

const appStartPromise =function(){
    return new Promise((resolve,reject)=>{
        const a=setTimeout(()=>{
            reject(new Error('get device timeout-自定义错误'));
        },1000);
        
        window.futrue.getDeviceIdCallback=(ret)=>{
            clearTimeout(a);
            resolve(ret);
        };
        window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getDeviceID');
    })
}


export const appStart =()=>{
    let deviceId='';
    return appStartPromise().then(ret=>{
        deviceId=ret;
    }).catch(err=>{
        console.error(err);
        deviceId=err?.message||'ee';
    }).then(()=>{
        console.log('%c [DEBUG]appstart:deviceId:%o','color:green',deviceId);
        const url = 'app/start.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            registrationID:deviceId,
            packageName:'com.futrue.uni'
        }
        return fuUserPost(url,param)
    });
}

export const getPersonDetail = (modelId)=>{
    const url = 'app/query_model_info.json';
    
    
    const param = {

        modelId:modelId+''
    }
    return fuUserPost(url,param);
}
// 2024-0624：user/queryModelPageInfo.json 修改为user/queryOneToOnePageInfo.json
export const getModelDetail = (modelId)=>{
    const url = 'app/model_info.json'
    
    const param = {

        modelId:modelId+''
    }
    return fuUserPost(url,param);
}
export const getModelPost = (modelId)=>{
    const url = 'cube/query_by_model.json'
    const param = {

        modelId:modelId+'',
        start:'1',
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const queryModelComment = (modelId,start='0')=>{
    const url = 'critical/list.json';
    const param ={
        start,
        pageSize:'20',
        modelId
    }
    return fuUserPost(url,param);
}

export const loadOpusList = (start='0')=>{
    
    
        const url ='cube/trends.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            start:'0',
            pageSize:'10'
        }
        return fuUserPost(url,param);
        
}

export const loadFollowOpusList = (start=0)=>{
    //#define queryRelationTrend @"index/queryRelationTrend.json"
    const url = 'cube/attention_trends.json';
    const param = {
        start:''+start,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const loadOpusListMore = (count='0') =>{
    
        const url ='cube/trends.json';
        // const profile = JSON.parse(localStorage.getItem('profile'));
        const param = {
            start:''+count,
            pageSize:'10'
        }
        return fuUserPost(url,param);
    
}

export const loadFollowOpusListMore = (count='0') =>{
    const url = 'cube/attention_trends.json';
    const param = {
        start:''+count,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const loadOpusListOfModel = (modelId,start='0')=>{
    
    const url = 'cube/model_trend_list.json';
        const param ={
            modelId,
            start,
            pageSize:'10'
        }
    return fuUserPost(url,param);
    
}

export const loadOpusListMoreOfModel = (modelId,count) =>{
    
        const url = 'cube/model_trend_list.json';
        const param ={
            modelId:modelId+'',
            start:count+'',
            pageSize:'10'
        }
        return fuUserPost(url,param);
    
    
}

export const charge = (payload)=>{
    const url = 'finance/consume.json';

    const param = Object.assign({
        opusId:'0', 
        giftId:'0',
        nmbs:'1',
        im:'0',
        liveId:'0' 
    },payload);
    console.log('[TRAE]charge param:',param)
    return fuUserPost(url,param).then(r=>{
        console.warn('[TRACE]fuUserPost return:',r);
        return r;
    });
}
// 请求礼物列表 scenes   1: 全部 (默认) 2: 1v1语音聊天 3: 1v1视频聊天 4: 直播
// 礼物有场景需要
export const giveGiftList = (scenes)=>{
    const url = 'config/sys/gifts.json';
    return fuUserPost(url,{scenes});
}
export const giveGift = (modelId,giftId,nmbs=1,liveId='0')=>{
    console.log('/=/第一次调用此接口');
    return charge({modelId,giftId,liveId:liveId,nmbs:''+nmbs,type:'4'})
}
// 获取亲密度vip折扣:模特ID
export const getvipIntimacyUrl = (viewId)=>{
    const url = 'app/intimacy.json';
    return fuUserPost(url,{viewId});
}
export const queryMyselfInfo = ()=>{
    const url ='app/get_mine_info.json';
    const param = {}
    return fuUserPost(url,param);
}
// 检查隐私模式
export const checkPrivateMode = (param)=>{
    const url ='permissions/check.json';
    return fuUserPost(url,param);
}
// 设置隐私模式
export const setPrivateMode = (param)=>{
    const url ='permissions/private_mode.json';
    return fuUserPost(url,param);
}

export const follow = (targetId)=>{
    const url = 'attention/add.json';
    const param = {
        followsUserId:targetId+''
    }
    return fuUserPost(url,param);
}

export const unfollow = (targetId)=>{
    const url = 'attention/cancel.json';
    const param = {
        followsUserId:targetId+''
    }
    return fuUserPost(url,param);
}

export const updateUser = (param)=>{
    /*
        if (self.isName) {
            params[@"nickName"] = self.nickName.text;
        }else
        {
            params[@"personalSign"] = self.nickName.text;
        }

        [[JxRequestTool sharedHelper] sendAsyncRequest:modifyUserInfo params:params success:^(id json) {
    */
    const url = "app/edit.json";

    console.log("[update user]:",param);
    
    return fuUserPost(url,param).then(data=>{
        console.log('return:',data);
        return data;
    });
}

export const rank = (type) =>{
    const url = 'leaderboard/rtc.json';
    const param = {type};
    
    return fuUserPost(url,param).then(data=>{
        
        console.log('rand result:',data);
        return data;
    })
}

export const rich = (type) =>{
    const url = 'leaderboard/fortune.json';
    const param = {type};
    return fuUserPost(url,param).then(data=>{
        console.log('rich result:',data);
        return data;
    })
}

export const switchDND =(checked)=>{
    const url = 'app/set_disturb.json';
    const param = {
        disturb:!!checked?'1':'0'
    };
    return fuUserPost(url,param).then(data=>{
        console.log('rich result:',data);
        return data;
    })
}

export const suggest = ()=>{
    return Promise.resolve();
}

export const cashVerify = (param)=>{
    const url = 'finance/withdraw_deposit.json';

    return fuUserPost(url,param);
}
export const queryMyWealth = ()=>{
    const url = 'finance/balance.json';
    return fuUserPost(url,{})
}

export const getWealthMobileCheckCode = ()=>{
    const url = 'finance/get_validate_code.json';
    return fuUserPost(url,{});
}

export const banUser = (id)=>{
    const url = 'break/relation/add.json';
    const param = {
        blackUserId:id+''
    };
    return fuUserPost(url,param);
}

export const banList = (start)=>{
    const url = 'break/relation/list.json';
    const param = {
        start:start+'',
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const unban = (userId)=>{
    const url = 'break/relation/delete.json';
    const param = {
        blackUserId:userId+''
    }
    return fuUserPost(url,param);
}

export const accountDetail = (start)=>{
    const url = 'finance/list.json';
    const param = {
        payType:'1',
        start:start+'',
        pageSize:'20'
    }
    return fuUserPost(url,param);
}

//申请成为主播
//用于主播认证功能
export const  applyHost = (param) =>{
    const url ='app/certificate.json';
    return fuUserPost(url,param);    
}

//获取分享信息
export const share = ()=>{
    const url = 'mine/share.json';
    return fuUserPost(url,{});
}

//搜索
export const search = (searchCriteria)=>{
    const url = 'app/search.json'
    const param = {
        nickName:searchCriteria
    }

    return fuUserPost(url,param);
}
// 提交视频评价
export const submitReview = (param) =>{
    // const url = 'user/evaluation/addEvaluation.json';---弃用
    const url='rtc/critical.json'

    return fuUserPost(url,param);
}
//like/dislike 动态
/*
//收藏

//取消收藏
*/
export const collectActivity = (opusId)=>{
    const url = 'favorite/add.json';
    const param = {
        opusId
    }
    return fuUserPost(url,param);
}
export const uncollectActivity = (opusId)=>{
    const url = 'favorite/cancel.json';
    const param = {
        opusId
    }
    return fuUserPost(url,param);
}

export const commentsByID = (opusId,start=0)=>{
    const url = 'discuss/list.json';
    const param = {
        opusId,
        start,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const addComment = (opusId,content)=>{
    const url = 'discuss/add.json';
    const param = {
        opusId,
        content
    }

    return fuUserPost(url,param);
}

export const queryMessageCenter = ()=>{
    const url = 'app/msg_report.json';
    const param = {

    }
    return fuUserPost(url,param);
}

export const getNoticeList = (start=0)=>{
    const url = 'bulletin/notices.json';
    const param ={
        start,
        pageSize:'50'
    }
    return fuUserPost(url,param);
}

export const getHint = (id)=>{
    const url = "bulletin/notice_info.json";
    const param = {
        id:''+id
    }
    return fuUserPost(url,param);
}

export const getNotice = (id)=>{
    const url ='/im/notification.json';
    const param = {
        id:''+id
    }
    return fuUserPost(url,param);
}


export const convList =(start=0)=>{
    const url = 'rtc/call_records.json';
    const param = {
        start,
        pageSize:'25'
    }
    return fuUserPost(url,param);
}
// 查询vip开通记录
export const getPayVipList =(start=1)=>{
    const url = 'product/purchased.json';
    const param = {
        start,
        pageSize:'10'
    }
    return fuUserPost(url,param);
}

export const withdrawRecord=(start=0)=>{
/*
 params[@"start"] = @(start);
    params[@"pageSize"] = @(10);
    params[@"status"] = @"ALL";
    params[@"userId"] = @(model.userId);

*/
    const url = 'finance/withdraw_list.json';
    const param = {
        start:start+'',
        pageSize:'10',
        status:'ALL',
    }

    return fuUserPost(url,param);
}

export const addressList =(start,kind)=>{
    /*
    //好友列表

    //关注列表

    //粉丝列表
    */
   const urls = ['attention/friends.json','attention/list.json','attention/fans.json'];
   const url = urls[kind];
   const param = {
    start:start+'',
    pageSize:'10',
   }
   return fuUserPost(url,param);
}

export const customerList = (modelId,start) =>{
    const url = 'leaderboard/consumers.json';
    const param = {
        start:start,
        pageSize:'10',
        playerId:modelId+''
    }
    return fuUserPost(url,param);
}

export const giftList = (modelId)=>{
    const url = 'leaderboard/gifts_income.json';
    const param = {
        simple:'0',
        playerId:modelId+'',
    }

    return fuUserPost(url,param);
}

export const circleQuery = (queryType,start=0) =>{
    const url = 'circle/query_circles.json';
    const param = {
        queryType:queryType+'',
        start:start+'',
        pageSize:'8'
    }
    // console.log('load....querytype:',queryType,' start:',start);
    return fuUserPost(url,param);
}

export const circleContentQuery = (modelId,queryType=0,start=1)=>{
    /*
    //模特-fu圈列表 分页 start  开始页码     pageSize 数量
    #define kqueryTrend @"circle/queryTrendByCondition.json"
    */
   const url = 'circle/trends.json';
   const param = {
    queryType:queryType+'',
    start:start+'',
    pageSize:8,
    modelId:modelId+''
   }
   console.log('%c circlequery param:%o','color:green',param);
   return fuUserPost(url,param);
}

export const joinCircle = (modelId)=>{
    return charge({
        modelId,type:'100'
    });
}


export const recommendList = (size)=>{
    const url = "app/recommends.json";

    const param = {
        maxTime:new Date().valueOf()*2+'',
        size : size+''
    }
    return fuUserPost(url,param);
}

export const randomMatch = ()=>{
    const url = 'app/match.json';
    const param = {
        maxTime:new Date().valueOf()+''
    }
    return fuUserPost(url,param);
}

export const switchHiddenMode = (type,value)=>{
    /*
        params[@"userId"] = @(model.userId);
    params[@"switchType"] = @"STEALTH";
    params[@"switchValue"] = on ? @"ON" : @"OFF";
    
    */
       /**
     * 设置隐身模式
     *
     * @param context
     * @param switchType  STEALTH(隐身模式开关) , DISTRUBMESSAGE (消息勿扰，预留) HIDE_SCREEN_PROMPT(关闭飘屏)
     * @param switchValue ON (开启) OFF(关闭 ）
     */

    const url = 'app/switcher.json';
    const param = {
        switchType:type,
        switchValue:value
    }

    return fuUserPost(url,param);
}

export const getCallUserInfo =(modelId) =>{
    const url = 'user/getCallUserInfo.json';
    const param = {
        callUserId:modelId+''
    }
    console.log('getCallUserInfo:',param);
    return fuUserPost(url,param);
}

export const finishLive=(liveId,channelName,modelId,viewerId)=>{

    
    // console.warn('finishLive no liveId:',channelName,modelId,viewerId)
    
    const url = 'live/finishLive.json';

    const param = {
        liveId,
        channelName,
        modelId,
        viewerId
    }
    console.log('%c FINISH LIVE IS CALLED.','color:green',param);
    return fuUserPost(url,param);
}

export const liveGetTimes = (modelId,viewerId,channelName,conversationId,expTime)=>{
    /*
        if (self.model.userType == 1) {
        
        params[@"userType"] = @(0);
        params[@"modelId"] = self.model.account;
        params[@"viewerId"] = @(model.userId);
    }else
    {
        params[@"userType"] = @(model.userType);
        params[@"modelId"] = @(model.userId);
        params[@"viewerId"] = self.model.account;
    }
    
    params[@"channelName"] = channel;
    params[@"conversationId"] = self.model.conversationId;
    params[@"expTime"] = @(self.currentExpTime);
    */
   const url = 'live/getTimes.json';
   const param = {
    userType:'0',
    modelId:modelId+'',
    viewerId:viewerId+'',
    channelName:channelName+'',
    conversationId:'',
    expTime:expTime+''
   }
   //console.log('%c liveGetTimes:%o','color:orange',param);
   return fuUserPost(url,param);
}
// 模糊开关 0: 模糊 1:清晰
export const checkVague = (modelId,vague,channelName) =>{
    const url = 'rtc/vague_setting.json';
    const param ={
        modelId,
        vague,
        channelName
    }
    console.log('%c setVague:%o','color:green',param);
    return fuUserPost(url,param);
}

export const opusDetail = (id)=>{
    const url = 'circle/trend_detail.json';
    const param = {
        opusId:""+id
    }
    return fuUserPost(url,param);
}

export const circleComment=(commentType,opusId,content,commentId)=>{
    const url = 'circle/publish_comment.json';
    const param = {
        opusId:''+opusId,
        commentType:''+commentType,
        content,
    }
    if(commentType=='SUB_COMMENT')param.commentId=commentId;
    console.log('comment post:',param);
    return fuUserPost(url,param);
}

export const addMessageByUser = (modelId,content)=>{
    const url ='mine/msg/send_msg.json';
    const param = {
        currtime:(new Date().valueOf())+'',
        messageUserId:''+modelId,
        content
    }
    return fuUserPost(url,param)
}

export const kqueryChatPrice =(myEMId,targetEMId)=>{
    const url = "mine/msg/price.json";
    const param = {
        currentAccount:myEMId,
        receiverAccount:targetEMId,
    }
    return fuUserPost(url,param);
} 

export const kqueryShareInfo = ()=>{
    const url = "circle/share_info.json";
    const param ={}
    return fuUserPost(url,param);
}

export const queryMyInviteData =()=>{
    const url = 'mine/get_invite.json';
    const param = {};
    return fuUserPost(url,param);
}

export const writeInviteCode=(uo)=>{
    const url ='app/add_invite.json';
    const {userid} =uo;

    const param = {
        inviteCode:userid,
        summaryType:'0'
    }
    return fuUserPost(url,param);
}
/*
//获取普通用户个人信息

params[@"showHiddenInfo"] = false;//默认false
self.isStealth = [json[@"stealth"] boolValue]; //是否是隐身状态
*/
export const queryUserInfo = (userId)=>{
    const url = 'app/user_info.json';
    const param = {
        queryUserId:''+userId
    }
    return fuUserPost(url,param);
}

export const editNoteName =(userId,name)=>{
    /*
     params[@"userId"] = @(model.userId);
        params[@"noteUserId"] = userId;
        params[@"noteName"] = self.beiZhuName.text;
        
    */
    const url = "remark/edit.json";
    const param = {
        noteUserId:userId,
        noteName:name
    }
    console.log('%c edit note name param:','color:green',param);
    return fuUserPost(url,param);
}

export const OneOnOneSameCityList=(geoLocation)=>{
    return Promise.resolve().then(()=>{
        if(!geoLocation)throw new Error('没有地址信息')
        /*

        */
       const url = 'user/location/searchNearbyModel.json';
       const param={
        latitude:geoLocation.latitude+'',
        longitude:geoLocation.longitude+'',
        country:geoLocation.country+'',
        city:geoLocation.city+'',
        provence:geoLocation.province+'',
        district:geoLocation.district+'',
        start:'0',
        pageSize:'8',
       }
       return fuUserPost(url,param);
    })
}
export const loadOneononeSameCityMore = (geoLocation,start)=>{
    return Promise.resolve().then(()=>{
        if(!geoLocation)throw new Error('没有地址信息')
        /*

        */
        const url = 'user/location/searchNearbyModel.json';
        const param={
         latitude:geoLocation.latitude+'',
         longitude:geoLocation.longitude+'',
         country:geoLocation.country+'',
         city:geoLocation.city+'',
         provence:geoLocation.province+'',
         district:geoLocation.district+'',
         start:''+start,
         pageSize:'8',
        }
        return fuUserPost(url,param);
    })
    
}

export const queryBanner = ()=>{
    const url = 'config/sys/banner.json';
    const param = {position:2}
    return fuUserPost(url,param);
}

export const spam = ({modelId,liveId,callUserId,operate,type,reCode,channelName})=>{
    /*
    params[@"userId"] = @(userId);
    params[@"modelId"] = @(modelId);
    params[@"liveId"] = @(liveId);
    params[@"callUserId"] = @(callUserId);
    params[@"type"] = @(type);
    params[@"operate"] = @(operate);

    if (reCode > 0) {
        params[@"reCode"] = @(reCode);
    }
    
    if (channelName.length > 0) {
        params[@"channelName"] = channelName;
    }
    
    */
   const url = 'live/spam/anchor';
   const param={
    modelId,
    liveId:(liveId||0),
    callUserId,
    type,
    operate,
    reCode,
    channelName
   };
   return fuUserPost(url,param);
}