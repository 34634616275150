import {fuUserPost} from './base';

export const checkLive = (modelId,viewerId,userType='0')=>{
    const url ='live/checkLive.json';
    
    const param = {        
        modelId,
        viewerId,
        userType
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);

}
// 检查rtc兼容:邀请人、被邀请人'rtc/checkRtcVer.json';  H5使用新逻辑，APP弃用这个
export const checkLive2 = (invitorId,inviteeId,liveType)=>{
    const url ='rtc/create.json';
    const param = {        
        invitorId,
        inviteeId,
        liveType    // VIDEO_CHAT视频 AUDIO_CHAT语音
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);

}
export const checkLive23 = (invitorId,inviteeId,liveType)=>{
    const url ='rtc/version.json';
    const param = {        
        invitorId,
        inviteeId,
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);

}
// 日志--收到邀请
export const acceptJoinRoomLog = (roomId)=>{
    const url ='rtc/accept.json';
    const param = {        
        roomId
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);
}
// 日志--加入视频:eventType 1. 拉取首帧视频成功 2. 拉取首帧视频超时
export const alreadyJoinRoomLog = (roomId,eventType,elapsed)=>{
    const url ='rtc/events.json';
    const param = {        
        roomId,
        eventType,
        elapsed
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);
}
// 加入房间
export const joinRoom = (roomId)=>{
    const url ='rtc/join.json';
    const param = {        
        roomId
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);

}
// 取消创建房间
export const cancelCreateRoom = (roomId)=>{
    const url ='rtc/cancelCreate.json';
    const param = {        
        roomId
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);
}
// 取消加入房间
export const cancelJoinRoom = (roomId)=>{
    const url ='rtc/refuse_join.json';
    const param = {        
        roomId
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);
}
// 离开房间
export const cancelRoom = (roomId)=>{
    const url ='rtc/left.json';
    const param = {        
        roomId
    };
    console.log('[DEBUG]param:',param);
    return fuUserPost(url,param);
}
export const getLiveToken = (channelName,modelId,viewerId,conversationId)=>
{
    const url = 'live/getLiveToken.json';
    let param;
    if(conversationId)
        param = {
            conversationId,
            channelName,
            userType:'0',
            modelId:modelId+'',
            viewerId:viewerId+'',
        }
    else param ={
        channelName,
        userType:'0',
        modelId:modelId+'',
        viewerId:viewerId+'',
    }

    return fuUserPost(url,param);
}

export const CALLMODEL = {
    avatar: null,
    giftId: 0,
    visiable: 0,
    killId: 0,
    code:0,
    message: null,
    nmbs: 0,
    type: 0
  }