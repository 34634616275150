import { useEffect, useState } from "react";
import Navigation from "../../components/navigation/navigation";
import { useNavigate } from "react-router-dom";
import "./mineVipManage.css";
import { Switch, Overlay, PasswordInput, Dialog, Toast } from "react-vant";
import * as service from "../../service";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";

const MineVipManagePrivateMode = () => {
  let theme = {
    color: "#FFF",
    bg: "linear-gradient(138.47deg, #FF6161 -2.56%, #FD8F8F 76.52%)",
  };
  const navigate = useNavigate();
  const [myInfo, setMyInfo] = useState(null);
  const [showHiddenDialog2, setShowHiddenDialog2] = useState(false); // 打开Svip弹窗
  const [showPage1, setShowPage1] = useState(true); // 是否显示组件
  const [pwd1, setpwd1] = useState(null); // 密码
  const [pwd2, setpwd2] = useState(null); // 密码

  useEffect(() => {
    service.checkPrivateMode().then((data) => {
      console.log("....myInfo:", data);
      if (data.code == 21) {
        navigate("/login");
      }
      setMyInfo(data.data);
    });
    console.log("/数据改变：");
  }, []); // 只执行一次：空数组[]，模拟 componentDidMount 这个生命周期

  return (
    <div className="mineVipManage mineVipManagePrivateMode">
      <Navigation
         style={{ top: CONST_TOP_ADJUST_HEIGHT }}
        theme={theme}
        height={"0.5rem"}
        title={"纯净模式"}
      ></Navigation>
      <div className={showPage1 ? "" : "mineVipManagePrivateModeShow"}>
        <p className="font-size24 text-center" style={{marginBottom:'34px'}}>纯净模式未开启</p>
        <div className="mineVipManagePrivateModeDiv">
          <img src="/static/images/mine-pwd1.png" />
          <div className="font-size13">
            在纯净模式中，我们精选了一批特定内容呈现，更加注重保护您的个人隐私；将会隐藏收支明细、通话记录、私信清空；每次纯净模式的切换都需要输入独立密码。
          </div>
        </div>
        <div className="mineVipManagePrivateModeDiv">
          <img
            src="/static/images/mine-pwd2.png"
            className="mineVipManagePrivateModeDivImg2"
          />
          <div className="font-size13">
            开启纯净模式，需先设置独立密码，如忘记密码可联系客服申请重置密码。
          </div>
        </div>
        <div
          className="mineVipManageBtn"
          onClick={() => {
            if (myInfo?.hasPermission) {
              if (myInfo.setPassTimes > 0) {
                console.log("/=/改变密码");
                navigate(
                  "/mineVipManagePwd?privateMode=" +
         
                    myInfo.privateMode
                );
              } else {
                setShowPage1(false);
              }
            } else {
              setShowHiddenDialog2(true);
            }
          }}
        >
          {myInfo?.privateMode ? "关闭" : "开启"}纯净模式
        </div>
      </div>
      <div className={showPage1 ? "mineVipManagePrivateModeShow" : ""}>
        <p className="font-size15 text-center">独立密码</p>
        <p className="font-size10  text-center mineVipManagePrivateModeShowP2">
          首次使用绿色版功能需要设置密码
        </p>
        <div className="mineVipManagePrivateModeShowDiv">
          <PasswordInput
            type="number"
            gutter={10}
            length={4}
            onSubmit={(val) => setpwd1(val)}
          />
        </div>

        <p className="font-size15 text-center mineVipManagePrivateModeShowP2">
          再次输入
        </p>
        <div className="mineVipManagePrivateModeShowDiv">
          <PasswordInput
            type="number"
            gutter={10}
            length={4}
            onSubmit={(val) => setpwd2(val)}
          />
        </div>
        <p className="mineVipManagePrivateModeShowRemark">注：</p>
        <p className="mineVipManagePrivateModeShowRemark">
          请牢记自己的二级密码，如忘记可通过申诉重置
        </p>
        <div
          className="mineVipManageBtn"
          onClick={() => {
            console.log(pwd1, !pwd1);
            if (!pwd1 || pwd1.length !== 4) {
              Toast.info("请输入完整四位数字密码");
              return;
            }
            if (pwd1 !== pwd2) {
              Toast.info("两次密码输入不一致");
              return;
            }
            const obj = {
              pass: pwd1,
              value: "ON",
              isReset: true,
            };
            service.setPrivateMode(obj).then((data) => {
              navigate("/app");
            });
          }}
        >
          确认密码且开启
        </div>
      </div>
      <Overlay
        visible={showHiddenDialog2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          background: "unset",
        }}
        onClick={() => setShowHiddenDialog2(false)}
      >
        <div className="mine-vipClass mineVipManageBg mineVipManageBgPrivateMode">
          <p className="mineVipManageBgP">纯净模式</p>
          <p>开通SVIP即可使用专属权限</p>
          <div
            className="mine-vipClassBtn"
            onClick={() => {
              navigate("/minePayVip");
            }}
          >
            立即开通
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default MineVipManagePrivateMode;
